@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  
  .border-top-color {
    border-top-color: '#ef3651';
  }
  
}

